import React, { useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { apolloClient } from "../lib/GraphQL/GraphqlWrapper";
import dateFuncs from "Form/dateFuncs";
import ResendButton, { resendableRow } from "./ResendButton";
import CloseButton, { closeableRow } from "./CloseButton";
import DiscardButton, { discardableRow } from "./DiscardButton";
import { AgGridReact } from "ag-grid-react";
import gql from "graphql-tag";

const GET_RELATED_SYNCS = gql`
  query RelatedSyncItems($id: String!, $flow: String!) {
    relatedSyncItems(id: $id, flow: $flow) {
      id
      lastSyncAt
      lastSyncLink
      lastSyncError
    }
  }
`;

const SyncItemsTable = ({
  syncItems,
  sendToGp,
  inputs,
  submitQuery,
  onChangeInput
}) => {
  const gridRef = useRef();
  const [defaultColDef, setDefaultColDef] = useState({
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    floatingFilter: true,
    filterParams: {
      maxNumConditions: 1
    }
  });

  const formatLastSyncAt = params => {
    let value = params.value ? dateFuncs.displayDateTime(params.value) : "";
    if (params.data.enqueued) {
      return value + "Is enqueued";
    }
    return value;
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "",
      field: "expand",
      width: 30,
      cellRenderer: "agGroupCellRenderer",
      valueGetter: null
    },
    {
      headerName: "Flow",
      field: "flow",
      width: 90,
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["CMP-EPI", "CMP-ERAP", "CMP-GP", "CMP-NED"]
      }
    },
    {
      headerName: "Contract",
      field: "contractCode",
      width: 120,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Type",
      field: "type",
      width: 190,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "NP_BUY_INVOICE",
          "NP_HIRE_INVOICE",
          "NP_HIRE_INVOICE_PO",
          "NP_HIRE_OFFRENTAL",
          "NP_RTO_INVOICE",
          "NP_RTO_INVOICE_PO",
          "NP_SERCALL",
          "CMP_BRANCH_HIRE",
          "CMP_BRANCH_ORDER",
          "CMP_BRANCH_HOME_MOD",
          "ORDER",
          "ITEM",
          "DISABLE_INV",
          "REENABLED_INV",
          "ADDRESS",
          "CLIENT",
          "HCP",
          "SHIPMENT"
        ]
      }
    },
    {
      headerName: "CMP Order Id",
      field: "orderId",
      width: 110,
      cellStyle: { textAlign: "center" },
      filter: "agTextColumnFilter",
      cellRenderer: params => (
        <a
          href={"/orders/" + params.data.orderId}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.value}
        </a>
      )
    },
    {
      headerName: "Invoice No",
      field: "gpInvoiceNumber",
      width: 110,
      cellStyle: { textAlign: "center" },
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Synced at",
      field: "lastSyncAt",
      width: 150,
      sort: "desc",
      filter: "agDateColumnFilter",
      filterValueGetter: params => {
        let cellDate = new Date(params.data.lastSyncAt);
        cellDate.setHours(0, 0, 0, 0);
        return cellDate;
      },
      valueFormatter: params => formatLastSyncAt(params)
    },
    {
      headerName: "Details",
      field: "lastSyncLink",
      width: 65,
      filter: false,
      cellStyle: { textAlign: "center" },
      cellRenderer: params => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          Details
        </a>
      )
    },
    {
      headerName: "Last error",
      field: "lastSyncError",
      flex: 1,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Attempts",
      field: "numberOfSyncAttempts",
      width: 90,
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Action",
      width: 230,
      field: "resendable",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["None", "Resend", "Discard", "Close"],
        suppressMiniFilter: true,
        suppressSorting: true
      },
      valueGetter: params => {
        let values = [];
        if (resendableRow(params.data)) {
          values.push("Resend");
        } else {
          values.push("None");
        }
        if (closeableRow(params.data)) values.push("Close");
        if (discardableRow(params.data)) values.push("Discard");
        return values;
      },
      cellRenderer: params => {
        return (
          <React.Fragment key={params.data.id}>
            <ResendButton row={params.data} sendToGp={sendToGp} />
            <CloseButton row={params.data} />
            <DiscardButton row={params.data} />
            {!resendableRow(params.data) && <em>none</em>}
          </React.Fragment>
        );
      }
    }
  ]);

  const [detailCellRendererParams, setDetailCellRendererParams] = useState({
    detailGridOptions: {
      columnDefs: [
        {
          headerName: "Synced at",
          field: "lastSyncAt",
          width: 120,
          sort: "desc",
          valueFormatter: params => formatLastSyncAt(params)
        },
        {
          headerName: "Details",
          field: "lastSyncLink",
          width: 80,
          cellStyle: { textAlign: "center" },
          cellRenderer: params => (
            <a href={params.value} target="_blank" rel="noopener noreferrer">
              Details
            </a>
          )
        },
        {
          headerName: "Error",
          field: "lastSyncError",
          flex: 1,
          filter: "agTextColumnFilter"
        }
      ],
      defaultColDef: {
        suppressHeaderMenuButton: true
      }
    },
    getDetailRowData: params => {
      apolloClient
        .query({
          query: GET_RELATED_SYNCS,
          variables: {
            id: params.data.syncId,
            flow: params.data.flow
          }
        })
        .then(result => {
          params.successCallback(result.data.relatedSyncItems);
        })
        .catch(error => {
          console.log("Error fetching related syncs", error);
          params.successCallback([]);
        });
    }
  });

  const clearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, []);

  const isRowMaster = useCallback(dataItem => {
    return dataItem.numberOfSyncAttempts > 1;
  }, []);

  return (
    <React.Fragment>
      <div className="search-wrappper">
        <div className="search-wrappper-block">
          <label>Number of days to show:</label>
          <input
            type="number"
            min={1}
            value={inputs.days}
            onChange={e => {
              const parsedDays =
                e.target.value === "" ? 0 : parseInt(e.target.value, 10);
              onChangeInput("days", parsedDays);
            }}
          />
          <label style={{ marginLeft: 20 }}>CMP Order Id:</label>
          <input
            type="text"
            value={inputs.orderId}
            onChange={e => onChangeInput("orderId", e.target.value)}
          />
          <label style={{ marginLeft: 20 }}>Flow:</label>
          <select
            value={inputs.flow}
            onChange={e => onChangeInput("flow", e.target.value)}
          >
            <option value="">All</option>
            <option value="CMP-EPI">CMP-EPI</option>
            <option value="CMP-ERAP">CMP-ERAP</option>
            <option value="CMP-GP">CMP-GP</option>
            <option value="CMP-NED">CMP-NED</option>
          </select>
          <label style={{ marginLeft: 20 }}>
            Resend only:
            <input
              name="resendableOnly"
              type="checkbox"
              checked={inputs.resendableOnly}
              onChange={e => onChangeInput("resendableOnly", e.target.checked)}
            />
          </label>
        </div>
        <button
          onClick={submitQuery}
          disabled={inputs.days <= 0}
          className="button primary"
          style={{ marginBottom: 20 }}
        >
          Go
        </button>
      </div>
      <div className="button-group" style={{ marginBottom: 5 }}>
        <button onClick={clearFilters} className="button button--small grey">
          Clear filters
        </button>
      </div>
      <div className="ag-theme-balham ag-grid-wrapper">
        <AgGridReact
          ref={gridRef}
          rowData={syncItems}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          floatingFiltersHeight={50}
          sortingOrder={["desc", "asc", null]}
          masterDetail={true}
          isRowMaster={isRowMaster}
          detailCellRendererParams={detailCellRendererParams}
        />
      </div>
    </React.Fragment>
  );
};

SyncItemsTable.propTypes = {
  syncItems: PropTypes.array.isRequired,
  sendToGp: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  submitQuery: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired
};

export default SyncItemsTable;
