import React from "react";
import PropTypes from "prop-types";

import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";

let cache = {};
const DashboardQuery = ({
  render,
  days,
  orderId,
  resendableOnly,
  flow
}) => {
  return (
    <SendToGpCaller
      render={({ sendToGp }) => (
        <Query
          query={gqlDashboardQuery()}
          variables={{
            days: days,
            orderId: orderId,
            resendableOnly: resendableOnly,
            flow: flow
          }}
          fetchPolicy="cache-and-network"
        >
          {({ error, data, networkStatus, refetch }) => {
            if (error && !cache.syncItems) {
              return <div>Error</div>;
            }
            // 1 == loading, 2 == variables changed, 4 == refetch
            if (
              networkStatus === 1 ||
              networkStatus === 2 ||
              networkStatus === 4
            ) {
              return <p>Loading...</p>;
            }
            if (data.syncItems) {
              cache = { syncItems: data.syncItems };
            }
            return render({ syncItems: cache.syncItems, sendToGp, refetch });
          }}
        </Query>
      )}
    />
  );
};

const gqlDashboardQuery = () => {
  return gql`
    query SyncItems(
      $days: Int!
      $orderId: String
      $resendableOnly: Boolean
      $flow: String
    ) {
      syncItems(
        days: $days
        orderId: $orderId
        resendableOnly: $resendableOnly
        flow: $flow
      ) {
        id
        type
        flow
        contractCode
        orderId
        gpInvoiceNumber
        lastSyncAt
        enqueued
        resendable
        lastSyncError
        numberOfSyncAttempts
        closeable
        syncId
        lastSyncLink
      }
    }
  `;
};

DashboardQuery.propTypes = {
  days: PropTypes.number.isRequired,
  orderId: PropTypes.string,
  resendableOnly: PropTypes.bool.isRequired,
  flow: PropTypes.string,
  render: PropTypes.func.isRequired
};

export default DashboardQuery;

const MUTATION_GQL = gql`
  mutation sendToGp($id: ID!, $model: String!, $flow: String!) {
    sendToGp(id: $id, model: $model, flow: $flow) {
      syncItem {
        id
        type
        flow
        orderId
        gpInvoiceNumber
        lastSyncAt
        enqueued
        resendable
        lastSyncError
        numberOfSyncAttempts
        closeable
        syncId
        lastSyncLink
      }
      errors {
        path
        message
      }
    }
  }
`;

class SendToGpCaller extends React.Component {
  state = { errors: null };
  render() {
    const { render } = this.props;
    return (
      <Mutation
        mutation={MUTATION_GQL}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => {
          const sendToGp = row => {
            update({
              variables: {
                id: row.id,
                model: row.type,
                flow: row.flow
              }
            });
          };
          return render({
            sendToGp
          });
        }}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

SendToGpCaller.propTypes = {
  render: PropTypes.func.isRequired
};
