import React from "react";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";
import SyncItems from "./SyncItems";

const DashboardApp = props => (
  <GraphqlWrapper>
    <div>
      <h2>Integration Dashboard</h2>
      <SyncItems />
    </div>
  </GraphqlWrapper>
);

export default DashboardApp;
