import React from "react";

import DashboardQuery from "./Queries/DashboardQuery";
import SyncItemsTable from "./SyncItemsTable";

class SyncItems extends React.Component {
  state = {
    inputs: {
      days: 0,
      orderId: "",
      resendableOnly: true,
      flow: ""
    },
    queryVals: {
      days: 0,
      orderId: "",
      resendableOnly: true,
      flow: ""
    }
  };

  render() {
    return (
      <div>
        <DashboardQuery
          days={this.state.queryVals.days}
          orderId={this.state.queryVals.orderId}
          resendableOnly={this.state.queryVals.resendableOnly}
          flow={this.state.queryVals.flow}
          render={({ syncItems, sendToGp, refetch }) => (
            <SyncItemsTable
              syncItems={syncItems}
              sendToGp={sendToGp}
              inputs={this.state.inputs}
              onChangeInput={(field, value) => this.handleChange(field, value)}
              submitQuery={() => this.submitQuery(refetch)}
            />
          )}
        />
      </div>
    );
  }

  submitQuery = refetch => {
    if (this.fastQuery()) {
      // Go button clicked without changing any input
      if (this.noInputsChanged()) {
        refetch();
      } else {
        this.setState({ queryVals: this.state.inputs });
      }
    }
  };

  fastQuery = () =>
    this.state.inputs.days < 11 ||
    this.state.inputs.orderId.length ||
    window.confirm(
      "WARNING: Searching more than 10 days without a CMP Order Id can take a long time and potentially slow down the system for other users."
    );

  noInputsChanged() {
    return (
      this.state.inputs.days === this.state.queryVals.days &&
      this.state.inputs.orderId === this.state.queryVals.orderId &&
      this.state.inputs.resendableOnly === this.state.queryVals.resendableOnly &&
      this.state.inputs.flow === this.state.queryVals.flow
    );
  }

  handleChange = (field, value) => {
    const inputs = { ...this.state.inputs, [field]: value };
    this.setState({ inputs });
  };
}

export default SyncItems;
