import React from "react";
import PropTypes from "prop-types";

const resendableRow = row => {
  return !!row.resendable;
};

class ResendButton extends React.Component {
  state = {
    isSending: false,
    numberOfSyncAttempts: this.props.row.numberOfSyncAttempts
  };

  render() {
    const { row } = this.props;
    return (
      resendableRow(row) && (
        <button
          onClick={this.send}
          className="button button--small"
          disabled={this.state.isSending}
          style={{ marginRight: 5 }}
        >
          {this.state.isSending ? " Resending..." : "Resend"}
        </button>
      )
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.row.numberOfSyncAttempts > prevState.numberOfSyncAttempts) {
      return {
        isSending: false,
        numberOfSyncAttempts: nextProps.row.numberOfSyncAttempts
      };
    } else return null;
  }

  send = () => {
    const { row, sendToGp } = this.props;
    this.setState({ isSending: true });

    sendToGp(row);
  };
}

ResendButton.propTypes = {
  row: PropTypes.object.isRequired,
  sendToGp: PropTypes.func.isRequired
};

export default ResendButton;
export { resendableRow };
